@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
body {
  margin: 0;
  font-family: "Share Tech Mono", monospace;
  background: #0F1011;
  color: #ffa500;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
#root{
  width: 100%;
}
.container {
  text-align: center;
  padding: 2rem;
  background-color: #1a1a1a;
  border: 1px solid #ffa500;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
  width: 90%;
  max-width: 600px;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #ffa500;
}

button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  background-color: #ffa500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #cc8400;
}

button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

input[type="file"] {
  padding: 0.5rem;
  background-color: black;
  color: #ffa500;
  border: 1px solid #ffa500;
  border-radius: 5px;
  cursor: pointer;
}

input[type="checkbox"] {
  accent-color: #ffa500;
  transform: scale(1.5);
  margin-right: 0.5rem;
}

label {
  font-size: 1rem;
  color: #ffa500;
}

.tx-container {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #1a1a1a;
  border: 1px solid #ffa500;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

.tx-container h3 {
  font-size: 1.25rem;
  color: #ffa500;
}

.tx-container p {
  font-size: 1rem;
  word-wrap: break-word;
  color: #ffa500;
}

/* Glowing Header */
.glow-header {
  font-size: 3rem;
  font-weight: 900;
  color: transparent;
  background: linear-gradient(90deg, #ff8800, #ffaa33, #ff8800);
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: glow-animation 3s infinite linear;
}

@keyframes glow-animation {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
}

/* Animated Button */
.glowing-button {
  background: linear-gradient(90deg, #ff8800, #ffaa33);
  padding: 10px 20px;
  border: none;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 25px;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* box-shadow: 0 0 10px rgba(255, 136, 0, 0.8), 0 0 40px rgba(255, 136, 0, 0.4); */
}

.glowing-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(255, 136, 0, 1), 0 0 50px rgba(255, 136, 0, 0.6);
}

.glowing-button:disabled {
  background: gray;
  cursor: not-allowed;
  box-shadow: none;
}

/* Card Styling */
.neumorphic-card {
  background: #1f1f1f;
  border-radius: 20px;
  box-shadow: -8px -8px 16px rgba(255, 255, 255, 0.05),
    8px 8px 16px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.neumorphic-card p {
  font-size: 1rem;
  color: #ffbb66;
}

/* Input Styling */
.custom-input {
  border: 1px solid #ff8800;
  background: #262626;
  color: #ffcc99;
  padding: 10px;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.custom-input:focus {
  outline: none;
  border: 1px solid #ffaa33;
  box-shadow: 0 0 8px #ffaa33;
}

/* Toast Notification */
.custom-toast {
  background: #1F1F1F;
  color: #ffffff;
  border-radius: 12px;
  border: solid 1px orange;
  /* box-shadow: 0 0 10px rgba(255, 187, 102, 0.8); */
  padding: 10px 20px;
}

button {
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}

.neumorphic-card {
  background: #1e1e2f;
  border-radius: 12px;
  padding: 20px;
  /* box-shadow: 10px 10px 20px #181825, -10px -10px 20px #24243a; */
}
